// ** Initial State
const initialState = {
  allData: [],
  projectList: [],
  data: [],
  total: 1,
  params: {},
  selectedUser: null
}

const users = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA':
      return { ...state, allData: action.data }
    case 'GET_DATA':
      let data = action.data
      if (action.params['status'] === 'pending') {
        data = []
        action.data.forEach(d => {
          data.push({ ...d, user: { email: d.email, name: d.email.split("@")[0] }, status: 'Pending' })
        })
      }
      return {
        ...state,
        data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_USER':
      return { ...state, selectedUser: action.selectedUser }
    case 'GET_PROJECT':
      let projectList = []
      action.data.forEach(proj => {
        projectList.push({ label: proj.name, value: proj.pk })
      });
      return { ...state, projectList }
    case 'ADD_USER':
      return { ...state }
    case 'DELETE_USER':
      return { ...state }
    default:
      return { ...state }
  }
}
export default users
