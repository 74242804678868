import mock from '../mock'

const data = {
  pricing: {
    basicPlan: {
      title: 'Basic',
      img: require('@src/assets/images/illustration/Pot1.svg').default,
      subtitle: 'A simple start for everyone',
      monthlyPrice: 989,
      yearlyPlan: {
        perMonth: 889,
        totalAnnual: 889
      },
      planBenefits: [
        'WhatsApp Support Inbox',
        '2 User Accounts',
        'Smart Customer lists',
        'Import customers',
        'Basic Chat Bot',
        'WhatsApp Broadcast',
        'Rs. .95 / Marketing Conversation',
        '1000 Free Service Conversations per month'
      ],
      popular: false
    },
    standardPlan: {
      title: 'Standard',
      img: require('@src/assets/images/illustration/Pot2.svg').default,
      subtitle: 'For small to medium businesses',
      monthlyPrice: 2199,
      yearlyPlan: {
        perMonth: 1999,
        totalAnnual: 1999
      },
      planBenefits: [
        'Roles & Permissions for Agents',
        '3 User Accounts',
        'Phone number masking from Agents',
        'Customer notes for Employees',
        'Detailed Reports & Analytics',
        'Retargeting',
        'Rs. .93 / Marketing Conversation',
        '1000 Free Service Conversations per month'
      ],
      popular: true
    },
    enterprisePlan: {
      title: 'Enterprise',
      img: require('@src/assets/images/illustration/Pot3.svg').default,
      subtitle: 'Solution for big organizations',
      monthlyPrice: "Custom",
      yearlyPlan: {
        perMonth: "Custom",
        totalAnnual: "Custom"
      },
      planBenefits: [
        'WhatsApp Login',
        '10 User Accounts',
        'Email Alerts for Events',
        'New Leads alert on WhatsApp & Email',
        'Custom CRM Integrations',
        'WhatsApp APIs & Webhook',
        'Rs. .93 / Marketing Conversation',
        '1000 Free Service Conversations per month'
      ],
      popular: false
    },
    qandA: [
      {
        question: 'Does my subscription automatically renew?',
        ans:
          'Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.'
      },
      {
        question: 'Can I store the item on an intranet so everyone has access?',
        ans:
          'Tiramisu marshmallow dessert halvah bonbon cake gingerbread. Jelly beans chocolate pie powder. Dessert pudding chocolate cake bonbon bear claw cotton candy cheesecake. Biscuit fruitcake macaroon carrot cake. Chocolate cake bear claw muffin chupa chups pudding.'
      },
      {
        question: 'Am I allowed to modify the item that I purchased?',
        ans:
          'Tart gummies dragée lollipop fruitcake pastry oat cake. Cookie jelly jelly macaroon icing jelly beans soufflé cake sweet. Macaroon sesame snaps cheesecake tart cake sugar plum. Dessert jelly-o sweet muffin chocolate candy pie tootsie roll marzipan. Carrot cake marshmallow pastry. Bonbon biscuit pastry topping toffee dessert gummies. Topping apple pie pie croissant cotton candy dessert tiramisu.'
      }
    ]
  }
}

mock.onGet('/pricing/data').reply(() => [200, data.pricing])
