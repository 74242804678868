// ** Initial State
// ** Initial State
const initialState = {
  allData: [],
  data: [],
  botActions: [],
  customerList: [],
  params: {},
  selectedUser: null
}

const forms = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_BOT_ACTIONS':
      let botActions = action.data

      return {
        ...state,
        botActions
      }
    case 'GET_CUSTOMER_LIST':
      let customerList = []
      action.data.forEach(entry => {
        customerList.push({ ...entry, value: entry.pk, label: entry.name })
      })
      return {
        ...state,
        customerList
      }
    case 'ADD_FORM':
      return { ...state }
    default:
      return { ...state }
  }
}
export default forms
