// ** Initial State
const initialState = {
  allData: {},
  funnel: {},
  projectList: [],
  data: [],
  total: 1,
  params: {},
  selectedUser: null
}

const emailLogs = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_DASH_DATA':
      return { ...state, allData: action.data, total: Object.keys(action.data).length }
    case 'GET_DATA':
      return { ...state, data: action.data }
    default:
      return { ...state }
  }
}
export default emailLogs
