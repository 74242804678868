// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import emailLogs from '@src/views/email/store/reducer'
import whatsapp from '@src/views/whatsapp/store/reducer'
import integrations from '@src/views/integrations/store/reducer'
import customers from '@src/views/customers/store/reducer'
import interests from '@src/views/fb_interest/store/reducer'
import dashboard from '@src/views/dashboard/store/reducer'
import chat from '@src/views/chat/store/reducer'
import members from '@src/views/members/store/reducer'
import settings from '@src/views/org-settings/store/reducer'
import conversations from '@src/views/conversation-logs/store/reducer'

const rootReducer = combineReducers({
  auth,
  emailLogs,
  whatsapp,
  members,
  dashboard,
  settings,
  interests,
  customers,
  chat,
  conversations,
  navbar,
  layout
})

export default rootReducer
