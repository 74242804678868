const initialState = {
  chats: [],
  broadcastReport: [],
  currentBroadcast: null,
  contacts: [],
  userProfile: {},
  selectedUserId: null,
  notes: [],
  customerList: [],
  myCustomerList: [],
  currentListMembers: [],
  currentListCount: 0,
  selectedUser: {}
}

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_USER_PROFILE':
      return { ...state, userProfile: action.userProfile }
    case 'GET_CHAT_CONTACTS':
      // return { ...state, chats: action.data.chatsContacts, contacts: action.data.contacts }
      return { ...state, chats: action.data }
    case 'REFRESH_CHAT':
      return { ...state, selectedUser: action.data }
    case 'DE_SELECT_CHAT':
      return { ...state, selectedUser: {}, selectedUserId: null }
    case 'SELECT_CHAT':
      if (action.data.type === "customerList") {
        let lastBroadcast = null;
        if (action.data.chat.length > 0) {
          lastBroadcast = action.data.chat.slice(action.data.chat.length - 1)
          lastBroadcast = lastBroadcast[0]
        }
        console.log("action.data.pk", action.data.pk)
        return { ...state, selectedUser: action.data, selectedUserId: action.data.pk, currentBroadcast: lastBroadcast }
      } else {
        return { ...state, selectedUser: action.data, selectedUserId: action.data.pk }
      }

    case 'SEND_NOTE':
      return { ...state, notes: action.data }
    case 'GET_NOTE':
      return { ...state, notes: action.data }
    case 'GET_CUSTOMER_LIST':
      return { ...state, customerList: action.data }
    case 'GET_LIST_MEMBERS':
      return { ...state, currentListMembers: action.data }
    case 'GET_LIST_COUNT':
      return { ...state, currentListCount: action.data }
    case 'GET_MY_CUSTOMER_LIST':
      return { ...state, myCustomerList: action.data }
    case 'SEND_BROADCAST':
      let newChat = state.selectedUser.chat
      if (newChat) {
        newChat.push(action.data.message)
      }
      console.log("currentBroadcast", action.data.broadcast)
      return { ...state, selectedUser: { ...state.selectedUser, chat: newChat }, currentBroadcast: action.data.broadcast }
    case 'SELECT_BROADCAST':
      return { ...state, currentBroadcast: action.data }
    case 'ADD_TO_CUSTOMER_LIST':
      return { ...state }
    case 'SEND_MSG':
      // ** Add new msg to chat
      if (Object.keys(state.selectedUser).length === 0) {
        return { ...state }
      }
      let newChatHistory = state.selectedUser.chat
      console.log("action.data", action.data)
      newChatHistory.push(action.data.message)
      return { ...state, selectedUser: { ...state.selectedUser, chat: newChatHistory } }
    default:
      return state
  }
}

export default chatReducer
