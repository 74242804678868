// ** Initial State
const initialState = {
  allData: [],
  customerList: [],
  data: [],
  columns: [],
  total: 1,
  params: {},
  selectedUser: null
}

const conversationLogs = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA':
      return { ...state, allData: action.data }
    case 'GET_LIST_DATA':
      return { ...state, customerList: action.data }
    case 'CONVERSATION_LOG':
      let data = action.data
      let rowData = {}
      let columns = {}
      console.log("Object.values(rowData)", data)
      data.forEach((d) => {
        if (!rowData.hasOwnProperty(d.conversation.pk)) {
          rowData[d.conversation.pk] = { customer: d.customer, conversation: d.conversation }
        }
        rowData[d.conversation.pk][d.data_key] = d.data_value
        columns[d.data_key] = true
      })
      console.log("Object.values(rowData)", Object.values(rowData))
      return {
        ...state,
        data: Object.values(rowData).reverse(),
        columns: Object.keys(columns),
        total: action.totalPages,
        params: action.params
      }
    case 'GET_USER':
      return { ...state, selectedUser: action.selectedUser }
    case 'GET_PROJECT':
      let projectList = []
      action.data.forEach(proj => {
        projectList.push({ label: proj.name, value: proj.pk })
      });
      return { ...state, projectList }
    case 'ADD_USER':
      return { ...state }
    case 'DELETE_USER':
      return { ...state }
    default:
      return { ...state }
  }
}
export default conversationLogs
