// ** Initial State
const initialState = {
  allData: [],
  projectList: [],
  data: [],
  logs: [],
  total: 1,
  totalLogCount: 1,
  params: {},
  logCustomers: [],
  selectedUser: null
}

const users = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA':
      return { ...state, allData: action.data }
    case 'GET_INTEGRATIONS':

      return { ...state, data: action.data }
    case 'GET_DATA':
      let data = action.data
      if (action.params['status'] === 'pending') {
        data = []
        action.data.forEach(d => {
          data.push({ ...d, user: { email: d.email, name: d.email.split("@")[0] }, status: 'Pending' })
        })
      }
      return {
        ...state,
        data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_USER':
      return { ...state, selectedUser: action.selectedUser }
    case 'LOG_CUSTOMERS':
      return { ...state, logCustomers: action.data }
    case 'GET_LOG':
      return { ...state, logs: action.data, totalLogCount: action.totalPages }
    case 'REFRESH_LOG':
      return { ...state, logs: action.data, totalLogCount: action.totalPages }
    case 'GET_PROJECT':
      let projectList = []
      action.data.forEach(proj => {
        projectList.push({ label: proj.name, value: proj.pk })
      });
      return { ...state, projectList }
    case 'ADD_USER':
      return { ...state }
    case 'DELETE_USER':
      return { ...state }
    default:
      return { ...state }
  }
}
export default users
